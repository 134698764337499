import React from "react";
// react plugin used to create google maps

// reactstrap components
import { Button, Form, Input, Container, Row, Col } from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterAboutUs from "components/Footers/FooterAboutUs.js";

function Contacto() {
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("contacto");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("contacto");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="main">
        <div className="section section-gray">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Contactanos</h2>
              </Col>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h3 className="title">Visitandonos en nuestras oficinas</h3>
                <p>
                Calle Magdalena de Kino Fco. I. Madero S/N entre Magdalena de Kino y Paseo Juan María de Salvatierra Colonia centro / C.P. 23880 Loreto, Baja California Sur
                </p>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <iframe 
                  src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d316.93402630748005!2d-111.3427066820686!3d26.01066962587096!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x86b43b5a03905a9d%3A0xd7202c01c8167140!2sH.%20Ayuntamiento%20de%20Loreto!5e0!3m2!1ses-419!2smx!4v1726720327365!5m2!1ses-419!2smx" 
                  width="100%" 
                  height="450" 
                  style={{border:0}}
                  allowfullscreen="" 
                  loading="lazy" 
                  referrerpolicy="no-referrer-when-downgrade">

                </iframe>
              </Col>
            </Row>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="6">
                <h3 className="title">
                  <small>Tambien encuentranos en nuestras redes sociales</small>
                </h3>
                <Button
                      className="btn-just-icon btn-round mr-1"
                      color="facebook"
                      href="https://www.facebook.com/"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="twitter"
                      href="https://x.com/?lang=es"
                      target="_blank"
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="instagram"
                      href="https://www.instagram.com/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                    </Button>
              </Col>
            </Row>
            
          </Container>
        </div>
      </div>
      
      <FooterAboutUs />
    </>
  );
}

export default Contacto;
