import React from "react";
// react plugin that creates an input with badges

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  CardImg,
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterAboutUs from "components/Footers/FooterAboutUs";

function Directorio() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("directorio");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("directorio");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="section section-team cd-section" id="teams">
      <div className="team-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Directorio de cabildo</h2>
                <h5 className="description">
                  Integrantes del H. XI Ayuntamiento de Loreto, Baja California Sur
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/34.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC PAZ DEL ALMA OCHOA AMADOR</CardTitle>
                        <h6 className="card-category">Presidenta Municipal</h6>
                        <p className="card-description">
                          Presidenta del H. XI Ayuntamiento de Loreto, Baja California Sur. 
                          <br></br>
                          <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/8.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">C MARIA MAGDALENA VILLALEJO ROMERO</CardTitle>
                        <h6 className="card-category">SINDICA MUNICIPAL</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/10.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">C GUADALUPE VILLALOBOS DAVIS</CardTitle>
                        <h6 className="card-category">PRIMERA REGIDORA</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/1.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">ING RENE HUMBERTO SAVIN MURILLO</CardTitle>
                        <h6 className="card-category">SEGUNDO REGIDOR</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/14.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC YANICSA DESSIRE HIGUERA FIOL</CardTitle>
                        <h6 className="card-category">TERCERA REGIDORA</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/13.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">PROFR HUMBERTO MURILLO DAVIS</CardTitle>
                        <h6 className="card-category">CUARTO REGIDOR</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/12.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC ANAI GUADALUPE ROMERO ARCE</CardTitle>
                        <h6 className="card-category">QUINTA REGIDORA</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/11.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC OSCAR RIVERA FAVELA</CardTitle>
                        <h6 className="card-category">SEXTO REGIDOR</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              
            </Row>
          </Container>
        </div>
      </div>
      <FooterAboutUs />
    </>
  );
}

export default Directorio;
