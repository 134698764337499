import React from "react";

// reactstrap components
import {
  Container,
  Row,
} from "reactstrap";

// core components

function SectionConstruccion() {
  return (
    <>
      <div
        className="background-img"
        style={{
          backgroundImage:
            "url(" +
            require("assets/img/lor.jpg") +
            ")",
        }}
      >
        <div className="filter" />
        <Container>
          <Row>
            <h1 className="title">
             Error <br />
              
            </h1>
          </Row>
          <div className="container-cards space-top">
            <Row>
              <h5 className="discover-pages text-center">
              Sitio en construcción! <br /> Lamentamos los inconvenientes, estamos trabajando en ello. <br />
              En unos dias estaremos de regreso.
              </h5>
            </Row>
           
          </div>
        </Container>
      </div>
    </>
  );
}

export default SectionConstruccion;
