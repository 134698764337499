import React, { useEffect } from "react";
import { NavLink } from "react-router-dom";

// reactstrap components
import {
  Button,
  Card,
  CardBody,
  CardFooter,
  Container,
  Row,
  Col,
  CardTitle,
} from "reactstrap";

// core components

function SectionCards() {
  useEffect(() => {
    // Inicializa el plugin de Facebook después de que el componente se monte
    if (window.FB) {
      window.FB.XFBML.parse();
    }
  }, []);
  return (
    <>
      <div className="section section-gray" id="cards">
        
        <Container className="tim-container">
          <h2 className="title text-center">Enterate</h2>
          <Row>
            <Col md="4" sm="6">
              <Card className="card-blog">
                <div className="card-image">
                  <NavLink to="https://www.facebook.com/share/p/KWMipXJKAwxA1A8W/" target="_blank">
                    <img
                      alt="..."
                      className="img"
                      src={require("assets/img/sections/noticia1.jpg")}
                      target="_blank"
                    />
                  </NavLink>
                </div>
                <CardBody className="text-center">
                  <CardTitle tag="h4">
                    Toma de Protesta de la Alcaldesa
                  </CardTitle>
                  <div className="card-description font-weight-bold">
                    Hoy, el municipio de Loreto celebra un momento histórico con la toma de protesta de nuestra alcaldesa Paz del Alma Ochoa Amador
                  </div>
                  <CardFooter>
                    <Button
                      className="btn-round"
                      color="default"
                      href="https://www.facebook.com/share/p/KWMipXJKAwxA1A8W/"
                      target="_blank"
                    >
                      Ver Noticia
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            <Col md="4" sm="6">
              <div
                className="fb-page"
                data-href="https://www.facebook.com/AyuntamientoDeLoreto"
                data-tabs="timeline"
                data-width=""
                data-height=""
                data-small-header="false"
                data-adapt-container-width="true"
                data-hide-cover="true"
                data-show-facepile="true"
              >
                <blockquote
                  cite="https://www.facebook.com/AyuntamientoDeLoreto"
                  className="fb-xfbml-parse-ignore"
                >
                  <a href="https://www.facebook.com/AyuntamientoDeLoreto">Facebook</a>
                </blockquote>
              </div>
            </Col>
            <Col md="4" sm="6">
              <Card className="card-blog">
                <div className="card-image">
                  <NavLink to="https://www.facebook.com/share/p/1Mg6K6MERN6gxoyJ/" target="_blank">
                    <img
                      alt="..."
                      className="img"
                      src={require("assets/img/sections/noticia2.jpg")}
                    />
                  </NavLink>
                </div>
                <CardBody className="text-center">
                  <CardTitle tag="h4">
                    Primera sesión de Cabildo
                  </CardTitle>
                  <div className="card-description font-weight-bold">
                    Realizaron esta mañana de jueves la primera sesión de Cabildo mediante la cual tomaron la protesta de Ley
                  </div>
                  <CardFooter>
                    <Button
                      className="btn-round"
                      color="default"
                      href="https://www.facebook.com/share/p/1Mg6K6MERN6gxoyJ/"
                      target="_blank"
                      
                    >
                      Ver Noticia
                    </Button>
                  </CardFooter>
                </CardBody>
              </Card>
            </Col>
            
          </Row>
          
        </Container>
        <Container className="tim-container">
          <h2 className="title">Ligas de acceso</h2>
          <Row>
            <Col md="4" sm="6">
              <Card className="card-blog">
                <div className="card-image">
                  <NavLink to="https://predialloreto.com/cgi-sys/suspendedpage.cgi" target="_blank">
                    <img
                      alt="..."
                      className="img"
                      src={require("assets/img/sections/predial.jfif")}
                    />
                  </NavLink>
                </div>
              </Card>
              <Card className="card-blog">
                <div className="card-image">
                  <NavLink to="https://server.seseabcs.gob.mx/pdebcs/Funcionarios/Declaraciones/login.html" target="_blank">
                    <img
                      alt="..."
                      className="img"
                      src={require("assets/img/sections/plataforma_digital.jpeg")}
                    />
                  </NavLink>
                </div>
              </Card>
            </Col>
            <Col md="4" sm="6">
            <Card className="card-blog">
                <div className="card-image">
                  <NavLink to="https://oomsapaloreto.gob.mx" target="_blank">
                    <img
                      alt="..."
                      className="img"
                      src={require("assets/img/sections/oomsapal.png")}
                    />
                  </NavLink>
                </div>
              </Card>
            </Col>
            <Col md="4" sm="6">
              <Card className="card-blog">
                <div className="card-image">
                <NavLink to="https://coronavirus.bcs.gob.mx/" target="_blank">
                  <img
                    alt="..."
                    className="img"
                    src={require("assets/img/sections/cor.jfif")}
                  />
                </NavLink>
                </div>
              </Card>
              {/* <Card data-color="prima">
                <CardBody>
                  <div className="author">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="avatar img-raised mr-2"
                        src={require("assets/img/placeholder.jpg")}
                      />
                      <span>Presidenta</span>
                    </a>
                  </div>
                  <span className="category-social pull-right">
                    <i className="fa fa-quote-right" />
                  </span>
                  <div className="clearfix" />
                  <p className="card-description">
                    {'"'}Bienvenidos sean todos{'"'}
                  </p>
                </CardBody>
              </Card> */}
              
            </Col>
          </Row>
          
        </Container>
      </div>
    </>
  );
}

export default SectionCards;
