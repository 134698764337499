import React from "react";
// react plugin that creates an input with badges

// reactstrap components
import {
  
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterAboutUs from "components/Footers/FooterAboutUs";
import SectionCards2 from "views/index-sections/SectionCards2.js";
import SectionConstruccion from "views/index-sections/SectionConstruccion";

function Transparencia() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("transparencia");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("transparencia");
    };
  });
  return (
    <>
      <DangerNavbar />
      <SectionConstruccion />
      {/* <SectionCards2 /> */}
      <FooterAboutUs />
    </>
  );
}

export default Transparencia;
