import React from "react";
import { Link } from "react-router-dom";
import Headroom from "headroom.js";
import {
  Button,
  Collapse,
  NavbarBrand,
  Navbar,
  Nav,
  Container,
  UncontrolledTooltip,
  UncontrolledDropdown,
  DropdownToggle,
  DropdownMenu,
  DropdownItem,
} from "reactstrap";
// core components

function WhiteNavbar() {
  const [bodyClick, setBodyClick] = React.useState(false);
  const [collapseOpen, setCollapseOpen] = React.useState(false);
  React.useEffect(() => {
    let headroom = new Headroom(document.getElementById("navbar-main"));
    // initialise
    headroom.init();
  });
  return (
    <>
      {bodyClick ? (
        <div
          id="bodyClick"
          onClick={() => {
            document.documentElement.classList.toggle("nav-open");
            setBodyClick(false);
            setCollapseOpen(false);
          }}
        />
      ) : null}
      <Navbar className="fixed-top" expand="lg" id="navbar-main" color="primary">
        <Container>
          <div className="navbar-translate">
            <NavbarBrand style={{ padding: 0, margin: 5 }}  id="navbar-brand" to="/index" tag={Link}>
              {/* <img src={require("assets/img/logoPuebloMagico.png")} alt="Logo" style={{ height: '4.5em', paddingRight: '1em' }} /> */}
              <img src={require("assets/img/logogrisdesv.png")} alt="Logo" style={{ height: '4em' }} />
            </NavbarBrand>
            <UncontrolledTooltip placement="bottom" target="navbar-brand">
            H. XI AYUNTAMIENTO DE LORETO
            </UncontrolledTooltip>
            <button
              className="navbar-toggler"
              id="navigation"
              type="button"
              onClick={() => {
                document.documentElement.classList.toggle("nav-open");
                setBodyClick(true);
                setCollapseOpen(true);
              }}
            >
              <span className="navbar-toggler-bar bar1" />
              <span className="navbar-toggler-bar bar2" />
              <span className="navbar-toggler-bar bar3" />
            </button>
          </div>
          <Collapse navbar isOpen={collapseOpen}>
            <Nav className="ml-auto" navbar>
              <UncontrolledDropdown nav inNavbar>
                <DropdownToggle className="mr-2" color="default" caret nav>
                  Directorio
                </DropdownToggle>
                <DropdownMenu className="dropdown-danger" right>
                  <DropdownItem to="/directorio" tag={Link}>
                    Cabildo
                  </DropdownItem>
                  <DropdownItem to="/directorio-ayuntamiento" tag={Link}>
                    Ayuntamiento
                  </DropdownItem>
                </DropdownMenu>
              </UncontrolledDropdown>
              <NavbarBrand id="navbar-brand1" to="/vision-mision" tag={Link}>
                Misión y Vision
              </NavbarBrand>
              {/* <NavbarBrand id="navbar-brand2" to="/organigrama" tag={Link}>
                Organigrama
              </NavbarBrand> */}
              
              {/* <NavbarBrand id="navbar-brand3" to="/directorio" tag={Link}>
                Directorio
              </NavbarBrand> */}
              <NavbarBrand id="navbar-brand4" to="/contacto" tag={Link}>
                Contacto
              </NavbarBrand>
              <NavbarBrand id="navbar-brand5" to="https://transparencia.loreto.gob.mx/transparencia" tag={Link}>
                Transparencia
              </NavbarBrand>
              <NavbarBrand id="navbar-brand6" to="/tramites" tag={Link}>
                Tramites
              </NavbarBrand>
              {/* <Button
                className="btn-neutral"
                color="link"
                href="https://x.com/?lang=es"
                target="_blank"
              >
                <i className="fa fa-twitter" />
              </Button> */}
              <Button
                className="btn-neutral"
                color="link"
                href="https://www.facebook.com/AyuntamientoDeLoreto"
                target="_blank"
              >
                <i className="fa fa-facebook" />
              </Button>
              {/* <Button
                className="btn-neutral"
                color="link"
                href="https://www.instagram.com/"
                target="_blank"
              >
                <i className="fa fa-instagram" />
              </Button> */}
            </Nav>
          </Collapse>
        </Container>
      </Navbar>
    </>
  );
}

export default WhiteNavbar;
