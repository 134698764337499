import React from "react";
// react plugin that creates an input with badges

// reactstrap components
import {
  Card,
  CardBody,
  CardFooter,
  CardTitle,
  Container,
  Row,
  Col,
  CardImg,
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterAboutUs from "components/Footers/FooterAboutUs";

function DirectorioAyuntamiento() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("directorio-ayuntamiento");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("directorio-ayuntamiento");
    };
  });
  return (
    <>
      <DangerNavbar />
      <div className="section section-team cd-section" id="teams">
      <div className="team-3">
          <Container>
            <Row>
              <Col className="ml-auto mr-auto text-center" md="8">
                <h2 className="title">Directorio Ayuntamiento</h2>
                <h5 className="description">
                  Integrantes del H. XI Ayuntamiento de Loreto, Baja California Sur
                </h5>
              </Col>
            </Row>
            <div className="space-top" />
            <Row>
            <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/34.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC PAZ DEL ALMA OCHOA AMADOR</CardTitle>
                        <h6 className="card-category">PRESIDENTA MUNICIPAL</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/24.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC RICARDO ARCE REBOLLAR</CardTitle>
                        <h6 className="card-category">DIRECTOR DE RELACIONES PUBLICAS</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/9.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC DANIELA ROJAS ARCE</CardTitle>
                        <h6 className="card-category">SECRETARIA PARTICULAR</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/6.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">PROFR JESUS OSWALDO TALAMANTES LOPEZ</CardTitle>
                        <h6 className="card-category">DIRECTOR DE DIFUSION COMUNICACIÓN SOCIAL</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/32.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC EDUARDO YEE MANRIQUEZ</CardTitle>
                        <h6 className="card-category">SECRETARIO PRIVADO</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/21.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC CINDY JANET DIAZ TALAMANTES</CardTitle>
                        <h6 className="card-category">DIRECTORA GENERAL DEL SISTEMA DIF</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/15.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">C CIPRIANO PEREZ BAEZA</CardTitle>
                        <h6 className="card-category">SECRETARIO TECNICO</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/3.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">MIGUEL ENRIQUE ROMERO AGUILAR</CardTitle>
                        <h6 className="card-category">H. CUERPO DE BOMBEROS Y PARAMEDICOS</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              {/* <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/placeholder.jpg")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">C ANTONIO VERDUGO DAVIS</CardTitle>
                        <h6 className="card-category">ASESOR</h6>
                        <p className="card-description">
                          Telefono de contacto 612 205 2068
                        </p>
                        <CardFooter className="pull-left">
                          {/* <Button
                            className="btn-just-icon btn-link mr-1"
                            color="linkedin"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fa fa-linkedin" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-link mr-1"
                            color="dribbble"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fa fa-dribbble" />
                          </Button>
                          <Button
                            className="btn-just-icon btn-link"
                            color="pinterest"
                            href="#pablo"
                            onClick={(e) => e.preventDefault()}
                          >
                            <i className="fa fa-pinterest" />
                          </Button>
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col> */}
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/5.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">ING JAFET ALFREDO UZCANGA HIGUERA</CardTitle>
                        <h6 className="card-category">DIRECTOR DE INFORMATICA Y SISTEMAS</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/36.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC JUAN CARLOS CASTAÑEDA DAVIS</CardTitle>
                        <h6 className="card-category">SECRETARIO GENERAL</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/22.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">C.P. GABRIEL GARCIA GASPAR</CardTitle>
                        <h6 className="card-category">TESORERO MUNICIPAL</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/17.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">MERCEDES VIDAURRAZAGA VERDUGO</CardTitle>
                        <h6 className="card-category">DIRECTOR DE INGRESOS</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/19.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC DIGNA MURILLO QUINTANA</CardTitle>
                        <h6 className="card-category">DIRECTOR DE EGRESOS Y CONTROL PRESUPUESTAL</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/4.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC. CLAUDIO RUBIO QUINTANA</CardTitle>
                        <h6 className="card-category">DIRECTOR DE CONTABILIDAD Y CUENTA PUBLICA</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/20.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC JUAN CARLOS MURILLO DAVIS</CardTitle>
                        <h6 className="card-category">COORDINADOR DE ZOFEMAT</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img//directorio/7.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC MARTIN  MURILLO ROMERO</CardTitle>
                        <h6 className="card-category">CONTRALOR</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/16.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">C CARMINA SEDANO TALAMANTES</CardTitle>
                        <h6 className="card-category">OFICIAL MAYOR</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/18.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">C DANIELA COTA ROMERO</CardTitle>
                        <h6 className="card-category">DIRECTORA DE RECURSOS HUMANOS</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/23.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">IVAN BAUTISTA SAEZ</CardTitle>
                        <h6 className="card-category">DIRECTOR DE RECURSOS MATERIALES</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/33.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC DARIO  DAVIS GARAYZAR </CardTitle>
                        <h6 className="card-category">SECRETARIO DE BIENESTAR Y DESARROLLO ECONOMICO</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/30.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">JULIAN DELGADO VARGAS </CardTitle>
                        <h6 className="card-category">DIRECTOR DE SERVICIOS PUBLICOS</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/2.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC ALEX MARTIN ESTRADA MENDOZA</CardTitle>
                        <h6 className="card-category">DIRECTOR DE MEDIO AMBIENTE Y SUSTENTABILIDAD</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/35.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">ING. LUIS MIGUEL ACOSTA ACOSTA</CardTitle>
                        <h6 className="card-category">DIRECTOR DE OOMSAPAL</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/28.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC LUIS ENRIQUE AMADOR ROMERO</CardTitle>
                        <h6 className="card-category">DIRECTOR DE LA JUVENTUD</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              <Col md="6">
                <Card className="card-profile card-plain">
                  <Row>
                    <Col md="5">
                      <CardImg top tag="div">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/directorio/27.png")}
                          />
                        </a>
                      </CardImg>
                    </Col>
                    <Col md="7">
                      <CardBody className="text-left">
                        <CardTitle tag="h4">LIC JUANA FRANCISCA CRUZ RAMOS</CardTitle>
                        <h6 className="card-category">DIRECTORA DEL INSTITUTO DE LA MUJER</h6>
                        <p className="card-description">
                        <br></br>
                        <i class="fa fa-phone" aria-hidden="true" style={{ color: '#871E41' }}></i> 613 13 500 36
                        </p>
                        <CardFooter className="pull-left">
                          
                        </CardFooter>
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
              </Col>
              
            </Row>
          </Container>
        </div>
      </div>
      <FooterAboutUs />
    </>
  );
}

export default DirectorioAyuntamiento;
