import React from "react";
// react plugin used to create DropdownMenu for selecting items

// reactstrap components
import { Button, Container, Row, Col } from "reactstrap";

function FooterAboutUs() {

  return (
    <>
      <footer className="footer footer-big footer-black">
        <Container>
          <Row>
            
            <Col className="ml-auto mr-auto" md="10" sm="10" xs="12">
              <Row>
                <Col md="3" sm="6" xs="12">
                <Container>
                  <p>
                  Calle Fco. I. Madero S/N entre Magdalena de Kino y Paseo Juan María de Salvatierra 
                  </p>
                  <p>
                  Colonia centro / C.P. 23880 Loreto, Baja California Sur 
                  </p>
                  </Container>
                </Col>
                <Col md="4" sm="6" xs="12">
                  <Container>
                  <p>
                    Correo-e:
                  </p>
                  <p>
                    Comentarios / Dudas: presidencia@loreto.gob.mx
                  </p>
                  </Container>
                </Col>
                <Col md="3" sm="6" xs="12">
                <Container>
                  <p>
                    Teléfono:
                  </p>
                  <p>
                    Oficina : (613) 1350036
                  </p>
                  </Container>
                </Col>
                <Col md="2" xs="6">
                  <div className="social-area">
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="facebook"
                      href="https://www.facebook.com/AyuntamientoDeLoreto"
                      target="_blank"
                    >
                      <i className="fa fa-facebook" />
                    </Button>
                    {/* <Button
                      className="btn-just-icon btn-round mr-1"
                      color="twitter"
                      href="https://x.com/?lang=es"
                      target="_blank"
                    >
                      <i className="fa fa-twitter" />
                    </Button>
                    <Button
                      className="btn-just-icon btn-round mr-1"
                      color="instagram"
                      href="https://www.instagram.com/"
                      target="_blank"
                    >
                      <i className="fa fa-instagram" />
                    </Button> */}
                  </div>
                </Col>
              </Row>
              <hr />
              <div className="copyright">
                <div className="pull-left">
                  © {new Date().getFullYear()} H. XI AYUNTAMIENTO DE LORETO
                </div>
                {/* <div className="links pull-right">
                  <ul>
                    <li className="mr-1">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Company Policy
                      </a>
                    </li>
                    |{" "}
                    <li className="mr-1">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Terms
                      </a>
                    </li>
                    |{" "}
                    <li>
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Privacy
                      </a>
                    </li>
                  </ul>
                </div> */}
              </div>
            </Col>
          </Row>
        </Container>
      </footer>
    </>
  );
}

export default FooterAboutUs;
