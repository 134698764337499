/*!

=========================================================
* Paper Kit PRO React - v1.3.1
=========================================================

* Product Page: https://www.creative-tim.com/product/paper-kit-pro-react
* Copyright 2023 Creative Tim (http://www.creative-tim.com)

* Coded by Creative Tim

=========================================================

* The above copyright notice and this permission notice shall be included in all copies or substantial portions of the Software.

*/

import React from "react";
import ReactDOM from "react-dom/client";
import { BrowserRouter, Route, Navigate, Routes } from "react-router-dom";

// styles
import "assets/css/bootstrap.min.css";
import "assets/scss/paper-kit.scss";
import "assets/demo/demo.css";
import "assets/demo/react-demo.css";
// pages
import Index from "views/Index.js";
import NucleoIcons from "views/NucleoIcons.js";
import VisionMision from "views/examples/VisionMision.js";
import Directorio from "views/examples/Directorio.js";
import Tramites from "views/examples/Tramites.js";
import Transparencia from "views/examples/Transparencia.js";
import Organigrama from "views/examples/Organigrama.js";
import Contacto from "views/examples/Contacto.js";
import DirectorioAyuntamiento from "views/examples/DirectorioAyuntamiento.js";
// others

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(
  <BrowserRouter>
    <Routes>
      <Route path="/index" element={<Index />} />
      <Route path="/nucleo-icons" element={<NucleoIcons />} />
      <Route path="/vision-mision" element={<VisionMision />} />
      <Route path="/transparencia" element={<Transparencia />} />
      <Route path="/directorio" element={<Directorio />} />
      <Route path="/organigrama" element={<Organigrama />} />
      <Route path="/contacto" element={<Contacto />} />
      <Route path="/directorio-ayuntamiento" element={<DirectorioAyuntamiento />} />
      <Route path="/tramites" element={< Tramites />} />
      <Route path="*" element={<Navigate to="/index" replace />} />
    </Routes>
  </BrowserRouter>
);
