import React from "react";
// react plugin that creates an input with badges

// reactstrap components
import {
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";

import FooterAboutUs from "components/Footers/FooterAboutUs";
// import SectionConstruccion from "views/index-sections/SectionConstruccion";

function Organigrama() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("organigrama");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("organigrama");
    };
  });
  return (
    <>
      <DangerNavbar />
      {/* <SectionConstruccion /> */}
      <div className="section secion-blog cd-section" id="blogs">
      <div className="blog-3">
          <Container>
            <Row>
              <h2 className="title">Organigrama</h2>
              <Col className="ml-auto mr-auto" md="12">
                <iframe
                  src={`https://docs.google.com/viewer?url=http://d5120c637723.sn.mynetname.net/ORGANIGRAMA_XI_LORETO%20(1).pdf&embedded=true`}
                  width="100%"
                  height="600px"
                  style={{ border: "none" }}
                  title="PDF Viewer"
                />
              </Col>
            </Row>
          </Container>
        </div>
      </div>
      <FooterAboutUs />
    </>
  );
}

export default Organigrama;
