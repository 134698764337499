import React from "react";
// plugin that creates slider

// reactstrap components
import {
  Card,
  CardHeader,
  CardBody,
  CardTitle,
  Collapse,
  Label,
  FormGroup,
  Input,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";
import FooterAboutUs from "components/Footers/FooterAboutUs.js";

function Tramites() {

  // states for collapses
  const [clothing, setClothing] = React.useState(true);
  const [showArea1, setShowArea1] = React.useState(true);
  const [showArea2, setShowArea2] = React.useState(false);
  const [showArea3, setShowArea3] = React.useState(false);
  const [showArea4, setShowArea4] = React.useState(false);
  const [showArea5, setShowArea5] = React.useState(false);
  const [showArea6, setShowArea6] = React.useState(false);
  const [showArea7, setShowArea7] = React.useState(false);
  const [showArea8, setShowArea8] = React.useState(false);
  const [showArea9, setShowArea9] = React.useState(false);
  const [showArea10, setShowArea10] = React.useState(false);
  const [showArea11, setShowArea11] = React.useState(false);
  const [showArea12, setShowArea12] = React.useState(false);
  const [showArea13, setShowArea13] = React.useState(false);
  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    
    document.body.classList.add("tramites");
    return function cleanup() {
      document.body.classList.remove("tramites");
    };
  });
  return (
    <>
      <DangerNavbar />
      {/* <EcommerceHeader /> */}
      <div className="wrapper">
           <div className="section section-gray">
          <Container>
            <h3 className="section-title">Busca tu Tramite</h3>
            <Row>
              <Col md="3">
                <Card className="card-refine">
                  <div
                    aria-expanded={true}
                    aria-multiselectable={true}
                    className="panel-group"
                    id="accordion"
                  >
                    <CardHeader
                      className="card-collapse"
                      id="priceRanger"
                      role="tab"
                    >
                      <h5 className="mb-0 panel-title">
                        {/* <a
                          aria-expanded={priceRange}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setPriceRange(!priceRange);
                          }}
                        >
                          Price Range <i className="nc-icon nc-minimal-down" />
                        </a> */}
                      </h5>
                    </CardHeader>
                    
                    <CardHeader
                      className="card-collapse"
                      id="clothingGear"
                      role="tab"
                    >
                      <h5 className="mb-0 panel-title">
                        <a
                          aria-expanded={clothing}
                          href="#pablo"
                          onClick={(e) => {
                            e.preventDefault();
                            setClothing(!clothing);
                          }}
                        >
                          Por Area <i className="nc-icon nc-minimal-down" />
                        </a>
                      </h5>
                    </CardHeader>
                    <Collapse isOpen={clothing}>
                      <CardBody>
                        {/* <FormGroup check>
                          <Label check>
                            <Input
                              defaultChecked
                              defaultValue=""
                              type="checkbox"

                            />
                            Todos <span className="form-check-sign" />
                          </Label>
                        </FormGroup> */}
                        <FormGroup check>
                          <Label check>
                            <Input
                              defaultChecked
                              defaultValue=""
                              type="checkbox"
                              checked={showArea1}
                              onChange={() => setShowArea1(!showArea1)}
                            />
                            Catastro <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input
                              defaultChecked
                              defaultValue=""
                              type="checkbox"
                              checked={showArea2}
                              onChange={() => setShowArea2(!showArea2)}
                            />
                            Contraloria <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue=""
                              type="checkbox"
                              checked={showArea3}
                              onChange={() => setShowArea3(!showArea3)}
                            />
                            Desarrollo Rural <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea4}
                              onChange={() => setShowArea4(!showArea4)}
                            />
                            Desarrollo Social <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea5}
                              onChange={() => setShowArea5(!showArea5)} />
                            Instituto Municipal de la Mujer <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea6}
                              onChange={() => setShowArea6(!showArea6)} />
                            Ingresos <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea7}
                              onChange={() => setShowArea7(!showArea7)} />
                            Medio Ambiente <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea8}
                              onChange={() => setShowArea8(!showArea8)} />
                            Obras Publicas <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea9}
                              onChange={() => setShowArea9(!showArea9)}
                            />
                            Paramedicos <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea10}
                              onChange={() => setShowArea10(!showArea10)} />
                            Proteccion Civil <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea11}
                              onChange={() => setShowArea11(!showArea11)} />
                            Secretaria General <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea12}
                              onChange={() => setShowArea12(!showArea12)} />
                            Transparencia <span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                        <FormGroup check>
                          <Label check>
                            <Input defaultValue="" type="checkbox"
                              checked={showArea13}
                              onChange={() => setShowArea13(!showArea13)} />
                            Turismo<span className="form-check-sign" />
                          </Label>
                        </FormGroup>
                      </CardBody>
                    </Collapse>
                    
                  </div>
                </Card>
                {/* end card */}
              </Col>
              <Col md="9">
                <div className="products">

                  {showArea1 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-map-o fa-4x" style={{ color: '#606060' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">Asignacion de Claves Catastrales</CardTitle>
                                <p className="card-description">
                                  INFORMACIÓN QUE IDENTIFICA LA LOCALIZACIÓN DEL BIEN INMUEBLE.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-usd fa-4x" style={{ color: '#606060' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">Avaluos Periciales y Catastrales</CardTitle>
                                <p className="card-description">
                                  SE DETERMINA EL VALOR FISCAL DE LOS PREDIOS Y CONSTRUCCIONES ADHERIDAS A ÉSTOS.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>


                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-search fa-4x" style={{ color: '#606060' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Busqueda de datos
                                </CardTitle>
                                <p className="card-description">
                                  SOLICITUD DE INFORMACIÓN QUE SE ENCUENTRA RESGUARDADA EN LA DIRECCIÓN
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>

                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i class="fa fa fa-arrows-alt fa-4x" style={{ color: '#871E41' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Deslindes o Levantamientos Prediales
                                </CardTitle>
                                <p className="card-description">
                                  REGULARIZACIÓN DE LIMITES Y COLINDANCIAS DE CADA PREDIO.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-map fa-4x" style={{ color: '#871E41' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Expedicion de croquis o planos
                                </CardTitle>
                                <p className="card-description">
                                  PLANO DE UN INMUEBLE QUE DESCRIBE LA SECCIÓN O SUBDIVISIÓN QUE INDICA LA LOCALIZACIÓN Y LOS LÍMITES DE LAS PROPIEDADES INDIVIDUALES. TAMBIÉN
                                  LLAMADO MAPA CATASTRAL
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" style={{ color: '#871E41' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Expedicion de Titulos de Propiedad
                                </CardTitle>
                                <p className="card-description">
                                  Tramite que da inicio a la regularización de un inmueble
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-object-group fa-4x" style={{ color: '#606060' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Fusión y Subdivisión de Predio
                                </CardTitle>
                                <p className="card-description">
                                  La unión en un solo predio de dos o más terrenos colindantes, subdivisión de predios es el acto mediante el cual se divide un predio en dos o más lotes; en donde los lotes resultantes cumplen con las dimensiones mínimas establecidas de acuerdo a su ubicación
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-building-o fa-4x" style={{ color: '#606060' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Impuesto Sobre Adquisicion de Bienes Inmuebles
                                </CardTitle>
                                <p className="card-description">
                                  IMPUESTO SOBRE ADQUISICIÓN DE INMUEBLES.</p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Catastro-Asignacion de Claves Catastrales.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-folder-open fa-4x" style={{ color: '#606060' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Manifestacion Catastral
                                </CardTitle>
                                <p className="card-description">
                                  DOCUMENTO QUE COMPRUEBA QUE UN PREDIO ESTÁ CATASTRADO.</p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  {/* Contraloria */}
                  {showArea2 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Contraloria-Denuncia Ciudadana.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-info-circle fa-4x" style={{ color: '#606060' }} aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">Denuncia Ciudadana</CardTitle>
                                <p className="card-description">
                                  Establecer la metodologia que permita atender las quejas y sugerencias de los/as ciudadanos del municipio de
                                  loreto; para proporcionar la calidad del servicio publico, mediante un trato digno a la ciudadania en un
                                  ambiente de respeto y equidad.

                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}

                  {showArea3 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Desarrollo Rural-Productores Acuicolas.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-users fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">Productores Acuicolas</CardTitle>
                                <p className="card-description">
                                  Propiciar las condiciones que permitan la unión, cooperación e integración de productores, empresas, personas
                                  jurídicas y / o agentes que intervengan en procesos productivos y comerciales del sector pesquero, mediante el
                                  mecanismo de alianzas estratégicas.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}

                  {showArea4 && (
                    <Row>

                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Desarrollo Social-Programa Equipo fotovoltaico.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-sun-o fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Programa equipo fotovoltaico
                                </CardTitle>
                                <p className="card-description">
                                  Fomentar el ordenamiento del sector energético e impulsar la transición energética a nivel estatal mediante el
                                  aprovechamiento de los potenciales de generación eléctrica a partir de energías alternativas y limpias
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}

                  {showArea5 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/IMMUJER-Gestion Servicio Psicologico.pdf")}>
                              <div className="card-center">
                                <i class="fa fa fa-medkit fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Gestión de Servicio Psicológico
                                </CardTitle>
                                <p className="card-description">
                                  Servicio dirigido a la ciudadanía en general atendiendo lo requerido en la prestación de servicio o gestión de programas que maneja el módulo de atención psicológica.
                                </p>
                              </div>
                              <div className="price">
                                <h5>Costo: Gratuito </h5>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}

                  {/* ingresos */}
                  {showArea6 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Ingresos-Licencia nueva Giro Alcoholes.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-beer fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Licencia Nueva Giro de Alcoholes
                                </CardTitle>
                                <p className="card-description">
                                  Expedición de Licencia a todos los propietarios o explotadores de giros con venta, distribución y/o consumo de bebidas embriagantes
                                </p>
                              </div>
                              <div className="price">
                                <h5> </h5>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Ingresos-Licencia Nueva Giro Comercial.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Licencia Nueva de Giro Comercial
                                </CardTitle>
                                <p className="card-description">
                                  Expedición de Licencia a todos los propietarios o explotadores de giros comerciales, industriales y de servicios.
                                </p>
                              </div>
                              <div className="price">
                                <h5> </h5>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Ingresos-Pago de Predial Urbano o Rustico.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-calculator fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Pago de Impuesto Predial
                                </CardTitle>
                                <p className="card-description">
                                  El impuesto predial es un tributo que los propietarios de bienes inmuebles (terrenos, casas, edificios, etc.) deben pagar de forma anual o trimestral al gobierno local o municipal.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Ingresos-Refrendo Licencia Comercial Alcoholes.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-beer fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Refrendo Licencia Comercial Alcoholes
                                </CardTitle>
                                <p className="card-description">
                                  Procedimiento para el cumplimiento del pago para el refrendo de licencia de giros comerciales con venta de alohol.</p>
                              </div>
                              <div className="price">
                                <h5> </h5>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Ingresos-Refrendo Licencia Comercial.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-folder-open fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Refrendo Licencia Comercial
                                </CardTitle>
                                <p className="card-description">
                                  Procedimiento para el cumplimiento del pago para el refrendo de licencia de giros comerciales, industriales y/o de servicios.</p>
                              </div>
                              <div className="price">
                                <h5> </h5>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Ingresos-Solicitud de Uso de Via Publica o bienes de uso comun.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-folder-open fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Solicitud de Uso de Via Publica o bienes de uso comun
                                </CardTitle>
                                <p className="card-description">
                                  Autorización de uso de vía pública para uso comercial o particular, según sea el caso. </p>
                              </div>
                              <div className="price">
                                <h5> </h5>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Ingresos-Solicitud Permiso Especial o Evento.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-folder-open fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Permiso para realizar evento y/o espectáculo
                                </CardTitle>
                                <p className="card-description">
                                  Expedición de permiso para realizar evento y/o espectáculo </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  {/* Medio Ambiente */}
                  {showArea7 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Medio Ambiente-Solicitud de DictamenTecnico.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Solicitud de Dictamen Tecnico

                                </CardTitle>
                                <p className="card-description">
                                  Documento que hace constar que la Dirección de Medio Ambiente, no tiene ningún inconveniente, en que se lleve a cabo uso de algún terreno o inmueble.
                                </p>
                              </div>
                              <div className="price">
                                <h5> </h5>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                  )}
                  {/* Obras Publicas */}
                  {showArea8 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP- Autorizacion de compatibilidad y Factibilidad de Uso de Suelo.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Autorización de compatibilidad y factibilidad de uso de suelo

                                </CardTitle>
                                <p className="card-description">
                                  Proceso para evaluar si un terreno o propiedad es adecuado para un tipo específico de desarrollo o actividad, considerando factores como la normativa urbanística, las características físicas del terreno, la infraestructura disponible y el impacto ambiental.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP-Autorizacion Uso de Suelo.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Autorización de uso de suelo habitacional y/o comercial

                                </CardTitle>
                                <p className="card-description">
                                  Consiste en autorizar el uso del suelo habitacional y/o comercial y señalar las disposiciones normativas para el aprovechamiento de los inmuebles ubicados en el territorio y a su vez regularizar el predio para tramites futuros.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP-Constancia Alineamiento.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Constancia de Alineamiento

                                </CardTitle>
                                <p className="card-description">
                                  Es la traza sobre el terreno que limita el predio respectivo con la vía pública en uso, y la futura vía pública determinada en los planos oficiales y proyectos legalmente aprobados
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP-Constancia de Numero Oficial.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Constancia de Numero Oficial

                                </CardTitle>
                                <p className="card-description">
                                  Se señalará para cada predio que tenga frente a la vía pública un solo número oficial que corresponderá a la entrada del mismo.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP-Licencia de Construccion.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Licencia de Construcción

                                </CardTitle>
                                <p className="card-description">
                                  La Licencia de construcción es un permiso requerido, normalmente por la administración local, para la realización de cualquier tipo de construcción
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP-Reporte de Alumbrado Publico.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-lightbulb-o fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Reporte de Alumbrado Publico

                                </CardTitle>
                                <p className="card-description">
                                  El alumbrado público es el servicio de luz eléctrica que el Municipio otorga a la comunidad y que se instala en calles, calzadas, plazas, parques, jardines y en general en todos los lugares públicos o de uso común
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP-Subdivision o Lotificacion.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Autorización de subdivisión y/o Lotificación.
                                </CardTitle>
                                <p className="card-description">
                                  Es la autorización previa para dividir o fraccionar uno o varios predios. Consiste en llevar a cabo la partición de un predio siempre y cuando los resultantes tengan frente a vía pública
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP-Terminacion de Obra.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Terminación de obra
                                </CardTitle>
                                <p className="card-description">
                                  La terminación de obra se expide una vez finalizada una obra, no sin antes verificar que haya cumplido con los requerimientos de la licencia
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/OP-Tramite Ante la SEMARNAT.pdf")}>
                              <div className="card-center">
                                <i class="fa fa-file-text fa-4x" aria-hidden="true"></i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Trámite de Congruencia de uso de suelo para trámite ante la SEMARNAT
                                </CardTitle>
                                <p className="card-description">
                                  Documento que se expide para la autorización de uso de suelo, este es un requisito que solicita la SEMARNAT para tramitar el uso de espacio en la Zona Federal Marítimo Terrestre
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>


                    </Row>
                  )}
                  {/* PaRAMEDICOS */}
                  {showArea9 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/paramedicos-Apoyo Eventos Especiales.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-users fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Apoyo en atencion a eventos especiales.
                                </CardTitle>
                                <p className="card-description">
                                  Servicio prestado por el H. Cuerpo de Bomberos y Paramédicos, como apoyo a eventos que requieran la supervisión del departamento y/o la orientación de actividades.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/paramedicos-Reporte de Incendio Forestal.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-fire fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  REPORTE DE INCENDIO FORESTAL.
                                </CardTitle>
                                <p className="card-description">
                                  Servicio que presta el honorable cuerpo de bomberos en casos de emergencia
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Paramedicos-Traslados Foraneos.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-ambulance fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Traslados Foraneos
                                </CardTitle>
                                <p className="card-description">
                                  Fuera del territorio del municipio de Loreto, Baja California Sur.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Paramedicos-Traslados Locales.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-ambulance fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                  Traslados Locales
                                </CardTitle>
                                <p className="card-description">
                                  Es el servicio de traslado que se presta dentro de la jurisdicción del Municipio de Loreto B.C.S.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>

                    </Row>
                  )}
                  {/* Proteccion civil */}
                  {showArea10 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Proteccion Civil-Operativos Diversos.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-users fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Operativos Diversos (Atención Ciudadana)
                                </CardTitle>
                                <p className="card-description">
                                Se coordina con grupos de emergencia, seguridad y voluntarios para salvaguardar la integridad física de participantes y la afluencia de espectadores.
                                </p>
                              </div>
                              <div className="price">
                                <h5> </h5>
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Proteccion Civil-Visita de Inspeccion.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-eye fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Visita de Inspección
                                </CardTitle>
                                <p className="card-description">
                                Es responsabilidad de protección civil es de identificar las zonas y tipos de riesgo a que está expuesta la población del Municipio de Loreto, así como que se cumpla con las medidas de seguridad estipuladas en la nom-003-segob/2011/
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Proteccion Civil-Visto Bueno.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-thumbs-up fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Visto Bueno
                                </CardTitle>
                                <p className="card-description">
                                Que los establecimientos cuenten con el sistema de prevención y protección para sus propios bienes y su entorno y que los mismos realicen actividades tales como capacitar al personal que labora en ellas en materia de protección civil
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>

                    </Row>
                  )}
                  {/* Secretaria General */}
                  {showArea11 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Secretaria General-Certificacion de Documentos.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-file-text fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Certificación de Documentos
                                </CardTitle>
                                <p className="card-description">
                                Solamente se certifican documentos expedidos por el mismo ayuntamiento y que estos obren en documento original, copia simple, de manera digital u otro medio electrónico.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Secretaria General-Constancia de residencia.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-file-text fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Constancia de Residencia
                                </CardTitle>
                                <p className="card-description">
                                Documento mediante el cual el solicitante podrá acreditar su domicilio actual, así como su temporalidad en este municipio.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Secretaria General-Constancia Dependencia Economica.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-file-text fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Constancia de Dependencia Economica
                                </CardTitle>
                                <p className="card-description">
                                Documento mediante el cual el solicitante podrá acreditar que depende económicamente de otra persona
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Secretaria General-Constancia modo honesto de vivir.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-file-text fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Constancia Modo Honesto de Vivir
                                </CardTitle>
                                <p className="card-description">
                                Documento mediante el cual el solicitante podrá acreditar tener un modo honesto de vivir, de buena moral y observar respeto hacia sus iguales en sociedad. 
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Secretaria General-Permisos para Bailes,fiestas familiares, cabalgata.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-file-text fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Permisos para eventos, Bailes, Procesiones, Cabalgatas
                                </CardTitle>
                                <p className="card-description">
                                Se expide permiso por la autoridad municipal para realizar los eventos públicos y privados consistentes en bailes, fiestas familiares, kilómetros de plata, cabalgata, procesiones, desfiles, festivales, circos, etc
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Secretaria General-Permisos para realizar peleas de gallos y carreras de caballos.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-file-text fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Permisos para realizar peleas de gallos y carreras de caballos
                                </CardTitle>
                                <p className="card-description">
                                Se expide permiso por la autoridad municipal para realizar peleas de gallos y carreras de caballos
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/SecretariaGeneral-Constancia de Ingresos.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-file-text fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Constancia de Ingresos
                                </CardTitle>
                                <p className="card-description">
                                Documento mediante el cual el solicitante podrá acreditar sus ingresos económicos mensuales.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    )}
                                      {/* Transparencia */}
                  {showArea12 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Transp-Solicitud de Informacion Publica.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-file-text fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Solicitudes de Acceso a la Información Pública
                                </CardTitle>
                                <p className="card-description">
                                Otorgar al ciudadano la facilidad y el derecho de solicitar información al H. Ayuntamiento de Loreto, en apego a la Ley de Transparencia y Acceso a la Información Pública 
                                del Estado de B.C.S., en cualquiera de sus diferentes modalidades que la misma Ley establece.
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    )}
                                        {/* Turismo */}
                  {showArea13 && (
                    <Row>
                      <Col md="4" sm="4">
                        <Card className="card-product card-plain">
                          <div className="card-image">
                            <a href={require("assets/img/tramites/Transp-Solicitud de Informacion Publica.pdf")}>
                              <div className="card-center">
                                <i className="fa fa-info fa-4x" aria-hidden="true"> </i>
                              </div>
                            </a>
                            <CardBody>
                              <div className="card-description">
                                <CardTitle tag="h5">
                                Información Turística
                                </CardTitle>
                                <p className="card-description">
                                Información general al turista local, nacional y extranjero, sobre la entidad. 
                                </p>
                              </div>
                              <div className="price">
                                
                              </div>
                            </CardBody>
                          </div>
                        </Card>
                      </Col>
                    </Row>
                    )}
                  <Row>
                    {/* <Col md="4" sm="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img-rounded img-responsive"
                              src={require("assets/img/ecommerce/balmain_8.jpg")}
                            />
                          </a>
                          <CardBody>
                            <div className="card-description">
                              <CardTitle tag="h5">Glass Beads Skirt</CardTitle>
                              <p className="card-description">
                                Dresses &amp; Skirts
                              </p>
                            </div>
                            <div className="price">
                              <h5>7.500 €</h5>
                            </div>
                          </CardBody>
                        </div>
                      </Card>
                    </Col>
                    <Col md="4" sm="4">
                      <Card className="card-product card-plain">
                        <div className="card-image">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <img
                              alt="..."
                              className="img-rounded img-responsive"
                              src={require("assets/img/ecommerce/balmain_8.jpg")}
                            />
                          </a>
                          <CardBody>
                            <div className="card-description">
                              <CardTitle tag="h5">Velvet Mini Dress</CardTitle>
                              <p className="card-description">
                                Dresses &amp; Skirts
                              </p>
                            </div>
                            <div className="price">
                              <h5>3.500 €</h5>
                            </div>
                          </CardBody>
                        </div>
                      </Card>
                    </Col>

                    <Col className="offset-md-4" md="3">
                      <Button
                        className="btn-round"
                        color="default"
                        data-rotation-color="gray"
                        id="successBtn"
                        outline
                      >
                        Load more...
                      </Button>
                      <UncontrolledTooltip delay={0} target="successBtn">
                        This is a morphing button
                      </UncontrolledTooltip>
                    </Col> */}
                  </Row>
                </div>
              </Col>
            </Row>
          </Container>
        </div>
        {/* section */}
        {/* <div className="section section-blog">
          <Container>
            <Row>
              <Col md="4">
                <Card className="card-blog">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/sections/miguel-perales.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="card-category text-info">Enterprise</h6>
                    <CardTitle tag="h5">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        LinkedIn’s new desktop app arrives
                      </a>
                    </CardTitle>
                    <p className="card-description">
                      LinkedIn is today launching its official desktop
                      application for Windows 10, allowing the professional
                      social networking service to... <br />
                    </p>
                    <hr />
                    <CardFooter>
                      <div className="author">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar img-raised mr-2"
                            src={require("assets/img/faces/ayo-ogunseinde-2.jpg")}
                          />
                          <span>Mike John</span>
                        </a>
                      </div>
                      <div className="stats">
                        <i className="fa fa-clock-o mr-1" />5 min read
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-blog">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/sections/roger-keller.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="card-category text-success">Startups</h6>
                    <CardTitle tag="h5">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        MIT’s Cheetah 3 robot is built to save lives
                      </a>
                    </CardTitle>
                    <p className="card-description">
                      The latest version of MIT’s Cheetah robot made its stage
                      debut today at TC Sessions: Robotics in Cambridge, Mass.
                      It’s a familiar project... <br />
                    </p>
                    <hr />
                    <CardFooter>
                      <div className="author">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar img-raised mr-2"
                            src={require("assets/img/faces/kaci-baum-2.jpg")}
                          />
                          <span>Nickie Kelly</span>
                        </a>
                      </div>
                      <div className="stats">
                        <i className="fa fa-clock-o mr-1" />5 min read
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
              <Col md="4">
                <Card className="card-blog">
                  <div className="card-image">
                    <a href="#pablo" onClick={(e) => e.preventDefault()}>
                      <img
                        alt="..."
                        className="img img-raised"
                        src={require("assets/img/sections/joshua-earlesz.jpg")}
                      />
                    </a>
                  </div>
                  <CardBody>
                    <h6 className="card-category text-danger">
                      <i className="fa fa-free-code-camp mr-1" />
                      Enterprise
                    </h6>
                    <CardTitle tag="h5">
                      <a href="#pablo" onClick={(e) => e.preventDefault()}>
                        Lionel Richie says “Hello” to startup investors
                      </a>
                    </CardTitle>
                    <p className="card-description">
                      Because developing a doctor-on-demand service that would
                      allow personalized medical visits, booked through an app
                      on a user’s phone is... <br />
                    </p>
                    <hr />
                    <CardFooter>
                      <div className="author">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="avatar img-raised mr-2"
                            src={require("assets/img/faces/erik-lucatero-2.jpg")}
                          />
                          <span>Mike John</span>
                        </a>
                      </div>
                      <div className="stats">
                        <i className="fa fa-clock-o mr-1" />5 min read
                      </div>
                    </CardFooter>
                  </CardBody>
                </Card>
              </Col>
            </Row>
          </Container>
        </div> */}
        {/* section */}
        <FooterAboutUs />
      </div>
    </>
  );
}

export default Tramites;
