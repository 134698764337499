import React from "react";
// react plugin that creates an input with badges

// reactstrap components
import {
  Card,
  CardBody,
  CardTitle,
  Container,
  Row,
  Col,
} from "reactstrap";

// core components
import DangerNavbar from "components/Navbars/DangerNavbar.js";


import FooterAboutUs from "components/Footers/FooterAboutUs";

function VisionMision() {

  document.documentElement.classList.remove("nav-open");
  React.useEffect(() => {
    document.body.classList.add("vision-mision");
    window.scrollTo(0, 0);
    document.body.scrollTop = 0;
    return function cleanup() {
      document.body.classList.remove("vision-mision");
    };
  });
  return (
    <>
      <DangerNavbar />
     
      <div className="section secion-blog cd-section" id="blogs">
      <div className="blog-3">
          <Container>
            <Row >
              <Col className="ml-auto mr-auto" md="10">
                <h2 className="title">Misión y Visión</h2>
                <br />
                <Card className="card-plain card-blog">
                  <Row className="justify-content-center">
                    <Col md="4" className="text-center">
                      <div className="card-image">
                        <img
                          alt="..."
                          className="img"
                          src={require("assets/img/sections/mision.jfif")}
                        />
                      </div>
                    </Col>
                    <Col md="12" className="text-center">
                      <CardBody>
                        <h6 className="card-category text-info">Misión</h6>
                        <CardTitle tag="h3">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Ser un gobierno altamente humanista y transformador, conformado por ciudadanas y ciudadanos, comprometidos y sensibles que conduzca el esfuerzo social para el bien común del Municipio de Loreto y que  impulse políticas públicas de mediano y largo plazo para lograr cambios profundos, respetando y promoviendo la ley y el estado de derecho.

                          </a>
                        </CardTitle> 
                        {/* <p className="card-description">
                          No matter what happens — funding versus acquisition
                          versus who knows what — Ljung says SoundCloud is not
                          going away “anytime in the foreseeable future.” I hope
                          that’s true, but I can’t help but feel a bit
                          skeptical. — and…{" "}
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            Read More
                          </a>
                        </p>
                        <p className="author">
                          by{" "}
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <b>Megan Rose Dickey</b>
                          </a>
                          , 11 days ago
                        </p> */}
                      </CardBody>
                    </Col>
                  </Row>
                </Card>
                <Card className="card-plain card-blog">
                  <Row className="justify-content-center">
                    <Col md="12" className="text-center">
                      <CardBody>
                        <h6 className="card-category text-danger">
                          Visión
                        </h6>
                        <CardTitle tag="h3">
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          Lograr un Municipio creativo, inclusivo e innovador, que sea un referente Estatal y Nacional en materia de desarrollo sustentable en los aspectos económicos, urbanos, culturales y sociales, con una comunidad más participativa tanto en la decisión como en el esfuerzo por constituirse en un mejor Municipio.
                          </a>
                        </CardTitle>
                        {/* <p className="card-description">
                          These issues might be making it a bit harder for Uber
                          to hire right now in the competitive Silicon Valley
                          job market. Acqui-hiring companies like it’s doing
                          here with Swipe Labs lets it roll up a bunch of good
                          talent — and…{" "}
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            Read More
                          </a>
                        </p>
                        <p className="author">
                          by{" "}
                          <a href="#pablo" onClick={(e) => e.preventDefault()}>
                            <b>Josh Constine</b>
                          </a>
                          , 2 days ago
                        </p> */}
                      </CardBody>
                    </Col>
                    {/* <Col md="4">
                      <div className="card-image">
                        <a href="#pablo" onClick={(e) => e.preventDefault()}>
                          <img
                            alt="..."
                            className="img"
                            src={require("assets/img/sections/rawpixel-comm.jpg")}
                          />
                        </a>
                      </div>
                    </Col> */}
                  </Row>
                </Card>
              </Col>
            </Row>
          </Container>
        </div>
      </div> 
      <FooterAboutUs />
    </>
  );
}

export default VisionMision;
