import React, { useEffect, useState, useCallback } from "react";
import {
  Card,
  Container,
  Row,
  Col,
  Carousel,
  CarouselItem,
  CarouselIndicators,
  CarouselCaption,
} from "reactstrap";

// carousel items
const carouselItems = [
  {
    src: require("assets/img/sections/carrusel5.jpeg"),
    altText: "Here it is",
    caption: "Here it is",
    link: "https://transparencia.loreto.gob.mx/archivos/1/673618a9335cb_CONVOCATORIA%20TURISMO%20LTO%20CON%20FIRMA.pdf", // Añade el enlace aquí
  },
  {
    src: require("assets/img/sections/carrusel4.png"),
    altText: "Here it is",
    caption: "Here it is",
    link: "https://transparencia.loreto.gob.mx/?seccion=desarrollourbano2024", // Añade el enlace aquí
  },
  {
    src: require("assets/img/sections/semaforoepidemiologico.jpg"),
    altText: "Here it is",
    caption: "Here it is",
  },
  {
    src: require("assets/img/sections/carrusel2.jpg"),
    altText: "Somewhere else",
    caption: "Somewhere else",
  },
];

function SectionJavaScript() {
  // carousel states and functions
  const [activeIndex, setActiveIndex] = useState(0);
  const [animating, setAnimating] = useState(false);

  const next = useCallback(() => {
    if (animating) return;
    const nextIndex =
      activeIndex === carouselItems.length - 1 ? 0 : activeIndex + 1;
    setActiveIndex(nextIndex);
  }, [animating, activeIndex]);

  useEffect(() => {
    const interval = setInterval(() => {
      if (!animating) {
        next();
      }
    }, 5000);
    return () => clearInterval(interval);
  }, [animating, next]);

  const onExiting = () => {
    setAnimating(true);
  };
  const onExited = () => {
    setAnimating(false);
  };
  const previous = () => {
    if (animating) return;
    const nextIndex =
      activeIndex === 0 ? carouselItems.length - 1 : activeIndex - 1;
    setActiveIndex(nextIndex);
  };
  const goToIndex = (newIndex) => {
    if (animating) return;
    setActiveIndex(newIndex);
  };

  return (
    <>
      <div className="section section-blue javascript-components">
        <Container>
          <Row>
            <Col className="ml-auto mr-auto" md="12">
              <Card className="card-raised page-carousel">
                <Carousel
                  activeIndex={activeIndex}
                  next={next}
                  previous={previous}
                >
                  <CarouselIndicators
                    items={carouselItems}
                    activeIndex={activeIndex}
                    onClickHandler={goToIndex}
                  />
                  {carouselItems.map((item) => {
                    return (
                      <CarouselItem
                        onExiting={onExiting}
                        onExited={onExited}
                        key={item.src}
                      >
                        {item.link ? (
                          <img
                            src={item.src}
                            alt={item.altText}
                            className="d-block w-100"
                            onClick={() => {
                              window.open(item.link, "_blank", "noopener,noreferrer");
                            }}
                            style={{ cursor: "pointer" }}
                          />
                        ) : (
                          <img
                            src={item.src}
                            alt={item.altText}
                            className="d-block w-100"
                          />
                        )}
                        <CarouselCaption
                          captionText={item.caption}
                          captionHeader=""
                        />
                      </CarouselItem>
                    );
                  })}
                  <a
                    className="left carousel-control carousel-control-prev"
                    data-slide="prev"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      previous();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-left" />
                    <span className="sr-only">Previous</span>
                  </a>
                  <a
                    className="right carousel-control carousel-control-next"
                    data-slide="next"
                    href="#pablo"
                    onClick={(e) => {
                      e.preventDefault();
                      next();
                    }}
                    role="button"
                  >
                    <span className="fa fa-angle-right" />
                    <span className="sr-only">Next</span>
                  </a>
                </Carousel>
              </Card>
            </Col>
          </Row>
        </Container>
      </div>
    </>
  );
}

export default SectionJavaScript;